<template>
    <div class="row mt-2">
        <div class="col-3" style="text-align: center;">
            <img src="@/assets/logoTest.png" style="height: 200px; width: auto;">
        </div>
        <div class="col-2"></div>
        <div class="col-7" style="text-align: center;">
            <img src="@/assets/mottoTest.png" style="height: 240px;">
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeBanner'
}
</script>