<template>
    <div class="container p-1" style="background-color: lightgray;">
        <div class="row m-2">
            <h5 class="col text-start mt-2">
                <span>News & Events /</span>
                <span> 新闻发布</span>
            </h5>
        </div>
        <div class="row m-2">
            <div class="col vstack gap-2 m-2" style="text-align: left;">
                <div class="p-1 outline" style="background-color: white;">
                    Collectors favorites in 2024
                </div>
                <div class="p-1 outline" style="background-color: white;">
                    Upcoming Auctions in 2024
                </div>
                <div class="p-1 outline" style="background-color: white;">
                    Major website revamping coming soon
                </div>
                <div class="p-1 outline" style="background-color: white;">
                    Other News
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeNews'
}
</script>