<template>
    <div class="container p-1" style="background-color: lightgray;">
        <div class="row m-2">
            <h5 class="col text-start mt-2">
                <span>Discussion Forum /</span>
                <span> 百家争鸣</span>
            </h5>
        </div>
        <div class="row m-2">
            <div class="col vstack gap-2 m-2" style="text-align: left;">
                <div class="p-1 outline" style="background-color: white;">
                    Why are things so expensive?
                </div>
                <div class="p-1 outline" style="background-color: white;">
                    How to tell the time period of something I own?
                </div>
                <div class="p-1 outline" style="background-color: white;">
                    Discussion topic
                </div>
                <div class="p-1 outline" style="background-color: white;">
                    Other Discussions
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeForum'
}
</script>