<template>
    <div class="row my-5">
        <h3 class="col-md-3 py-1">Create Listing</h3>
        <div class="col-md-3 py-1">
        </div>
        <div class="col-md-6 py-1">
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreateListingHeader'
}
</script>