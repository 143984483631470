<template>
    <div class="container">
        <div class="row" style="height: 100px;"></div>
        <div class="row">
            <div class="col-3" style="text-align: right;">
                <h2>Contact us:</h2>
            </div>
            <div class="col-9"></div>
        </div>
        <div class="row mt-5">
            <div class="col-3" style="text-align: right;">
                <h4>
                    Our Services:
                </h4>
            </div>
            <div class="col-9" style="text-align: left;">
                <div style=""></div>
                <h5>
                    1. We welcome you to submit your art collection for auctioning
                </h5>
                <h5>
                    2. We provide free advisory valuation for your collection
                </h5>
                <h5>
                    (We can come to you within Alberta!)
                </h5>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-3" style="text-align: right;">
                <h4>
                    Email:
                </h4>
            </div>
            <div class="col-9" style="text-align: left;">
                <h5>
                    chinookartauctioner@gmail.com
                </h5>
                <div class="contact-top-padding" style="height: 20px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactBody'
}
</script>